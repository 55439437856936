import React, { useEffect } from "react";
import { GlobalStyle } from "../SearchPatient/SearchPatient";
import { ReactComponent as WebRTCCamera } from "../../assets/webRTCCamera.svg";
import { useState } from "react";
import devicesInfo from "../../helper/devicesInfos.json";
import {
  base64ToBlob,
  blobToFile,
  getBetweenParenthesis,
  getConnectedDevices,
  getConnectedVideoDevices,
  getkeyName,
  toastConstraint,
} from "../../helper/mediaDeviceHelper";
import { useCreateMediaMediaStream } from "../../hooks/useCreateMediaMediaStream";
import { DeviceVideo } from "./DeviceVideo";
import hotToast, { Toaster } from "react-hot-toast";
// import { ToastContainer, toast } from "react-toastify";
import { VideoTypes } from "../types";
import { NoStream } from "./NoStream";
import { useNavigate } from "react-router-dom";
import { io } from "socket.io-client";
import { ArrowLeftCircleIcon } from "@heroicons/react/24/solid";
import { useAgentSocket } from "../../services/socketIOContext";
import { PDFDocument } from "pdf-lib";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { RootState } from "src/store";
import { CONSULTATION_STATUSES } from "../../enums/consultation";
import WebSocketService from "../../services/Notification/socketService";
import { ExeName } from "../../enums/vitalSigns";
import { AdvancedParamsTools } from "./../../constants";
import { FileDropZone } from "./FileDropZone";


const AdvancedParametere = (props: any) => {
  // const { availableSensors, agentSocket } = useAgentSocket();
  const navigat = useNavigate();
  const [toolsIndex, setToolsIndex] = useState<number | null>(null);
  const [toolName, setToolName] = useState("");
  const [availableVideaoDevices, setAvailableVideaoDevices] =
    useState<VideoTypes>({
      otoScope: null,
      dermatoscope: null,
      iriscope: null,
      cameraMobile: null,
    });
  const agentSocket = io("http://localhost:3000", {
    reconnection: true,
    reconnectionDelay: 5000,
    reconnectionAttempts: 10,
  });

  const [streamDeviceId, setStreamDeviceId] = useState<string | null>(null);
  const deviceRef = React.useRef<HTMLVideoElement | any>(null);
  const [deviceIsClicked, setDeviceIsClicked] = useState(false);
  const [uncategorizedDevices, setUncategorizedDevices] = useState<any>([]);
  const [importedFiles, setImportedFiles] = useState<File[]>([]);
  const currentStep = useSelector(
    (state: any) => state.currentStep.currentStep
  );

  const availableServices = useSelector(
    (state: any) => state.mediotAgents.availableServices
  );

  const { MultimediaStream, setMultimediaStream } = useCreateMediaMediaStream(
    streamDeviceId,
    deviceRef
  );
  const consultationStatus = useSelector(
    (state: RootState) => state.consultationStatus.status
  );

  /**
   * handel sending the ECG file to the backend
   * @param base64String base64 ECG pdf file
   * @param link link to show the ECG file
   * @param absolute absolute path
   */
  const handleDecodeAndCheck = async (
    base64String: string,
    link: string,
    absolute: string
  ) => {
    try {
      const binaryData = atob(base64String);
      const binaryArray = new Uint8Array(new ArrayBuffer(binaryData.length));

      for (let i = 0; i < binaryData.length; i++) {
        binaryArray[i] = binaryData.charCodeAt(i);
      }

      const pdfDoc = await PDFDocument.load(binaryArray);
      const pdfBytes = await pdfDoc.save();
      let newWindow: any;
      setTimeout(() => {
        newWindow = window.open(link, "_blank", "rel=noopener noreferrer");
        //TODO: add a loader to load the data and cancel it here
        // newWindow.addEventListener("beforeunload", () => {
        //   console.log("Window/tab is being closed.");
        if (toolsIndex) {
          disableECG();
        }
        // });
      }, 2000);
      // TODO: post PDF
      //send file into backend
      //TODO: in case the url is not working
      // const pdfBytes = awaijpegt pdfDoc.save();
      // const blob = new Blob([pdfBytes], { type: "application/pdf" });
      // const blobUrl = URL.createObjectURL(blob);

      // Open the PDF in a new tab
      // window.open(blobUrl, "_blank");
    } catch (error: any) {
      console.error("Error:", error.message);
      disableECG();
      toast.warning("Veuillez verifier la connextion du cable d'ECG");
    }
  };

  const disableECG = () => {
    setToolsIndex(null);
    hotToast.success("ECG est bien arrêté");
    console.log("ECG est bien afficher");
    agentSocket?.off("ecg_cmd");
  };

  //TODO: useEffect for to display device stream
  useEffect(() => {
    switch (toolsIndex) {
      case 0:
        if (deviceIsClicked) {
          // console.log("ecg_report_cmd", agentSocket);
          // agentSocket?.emit("ecg_report_cmd");
          agentSocket?.emit("ecg_cmd");
          // agentSocket?.on("ecg_report", (data: any) => {
          //   const { link, pdfBase64, absolute } = JSON.parse(data);
          //   console.log(" res ecg_report_cmd", pdfBase64);

          //   //TODO::send data to backend as a file
          //   handleDecodeAndCheck(pdfBase64, link, absolute);
          //   // navigat(link);
          // });
        }
        break;

      case 1:
        if (availableVideaoDevices.dermatoscope) {
          // console.log(availableVideaoDevices.dermatoscop);
          setStreamDeviceId(availableVideaoDevices.dermatoscope);
        }
        break;
      case 2:
        if (availableVideaoDevices.otoScope) {
          setStreamDeviceId(availableVideaoDevices.otoScope);
        }
        break;
      case 3:
        if (availableVideaoDevices.iriscope) {
          setStreamDeviceId(availableVideaoDevices.iriscope);
        }
        break;
      case 4:
        if (availableVideaoDevices.cameraMobile) {
          setStreamDeviceId(availableVideaoDevices.cameraMobile);
        }
        break;
      case 5:
        if (agentSocket) {
          agentSocket.emit("usg_cmd");
          setDeviceIsClicked(true);
          agentSocket.on("usg", (data: any) => {
            // Parse the JSON string to get the image data
            const { image } = JSON.parse(data);

            // Convert the base64-encoded image to a file once
            const fileFromImage = blobToFile(
              base64ToBlob(image),
              toolName + Date.now() + ".png"
            );

            // Create a new file object with a preview URL
            const newFile = Object.assign(fileFromImage, {
              preview: URL.createObjectURL(fileFromImage),
            });

            setImportedFiles((prevFiles): any => {
              // Add the new file to the existing files
              return [...prevFiles, newFile];
            });
          });
        }

        //  else if (!deviceIsClicked) {
        //  agentSocket?.exitApp();

        // }
        break;
    }

    if (deviceRef.current?.srcObject && !deviceIsClicked) {
      handleRemoveStream();
    }
  }, [toolsIndex]);

  const handleRemoveStream = () => {
    console.log("remove stream");
    stopVideoTrack();
    setStreamDeviceId(null);
    setMultimediaStream(null);
  };

  const stopVideoTrack = () => {
    MultimediaStream?.getTracks().forEach((track: any) => {
      track.stop();
    });
    if (deviceRef.current?.srcObject) deviceRef.current.srcObject = null;
  };

  //TODO: ended

  useEffect(() => {
    console.log("availableVideaoDevices", availableVideaoDevices);
  }, [availableVideaoDevices]);

  useEffect(() => {
    console.log("main camera before", availableVideaoDevices);
    console.log("before uncategorizedDevices", uncategorizedDevices);
    // Check if mainCamera is set, if not, set one from uncategorizedDevices
    if (
      !availableVideaoDevices.mainCamera &&
      uncategorizedDevices.length > 0
    ) {
      const mainCameraDevice = uncategorizedDevices[0];
      
      setAvailableVideaoDevices((prev: any) => ({
        ...prev,
        mainCamera: mainCameraDevice.deviceId,
      }));
      // dispatch(
      //   updateAvailableVideoDevices({
      //     category: "mainCamera",
      //     deviceId: mainCameraDevice.deviceId,
      //   })
      // );
      console.log("Set as main camera:", uncategorizedDevices);
    } else if (!availableVideaoDevices.mainCamera) {
      // Fire a message if no device could be set as mainCamera
      // toast.warning("No available device could be set as main camera.");
      console.log("No default available device could be set as main camera.");
    }
  
    return () => {
      
    }
  }, [uncategorizedDevices])
  

  /**
   * Responsible to set object containing the medical devices by their name
   * @param label
   * @param deviceId
   * @returns
   */

  const handleBackButtonClick = () => {
    console.log(
      "consultaion status",
      consultationStatus,
      "currentStep",
      currentStep
    );
    if (
      currentStep !== 0 &&
      consultationStatus == CONSULTATION_STATUSES.ON_GOING
    ) {
      try {
        props.setSelectedPeripherique(-1);
      } catch (error) {
        navigat(-1);
      }
    } else {
      navigat(-1);
    }
  };


  const labelExists = (label: string) => {
    return devicesInfo.some((category) => {
      return Object.values(category).some((camerasArray) => {
        return camerasArray.some((camera: any) => camera.label.includes(label));
      });
    });
  };

  /**
   *
   */
  const handelDeviceChange = async () => {
    try {
      const connectedDevices = await getConnectedVideoDevices("videoinput");
      console.log("device changed", connectedDevices);

      let uncategorizedDeviceslist = [];

      for (const device of connectedDevices) {
        const deviceLabel = getBetweenParenthesis(device.label);
        
        if (deviceLabel) {

          console.log("device label exists", device);
          
          if (!labelExists(deviceLabel)) {
            uncategorizedDeviceslist.push(device);
          } else {
            // If label exists, categorize the device
            devicesInfo.forEach((category) => {
              Object.values(category).forEach((camerasArray: any) => {
                camerasArray.forEach((camera: any) => {
                  if (camera.label.includes(deviceLabel)) {
                    setAvailableVideaoDevices((prev: any) => ({
                      ...prev,
                      [getkeyName(category)]: device.deviceId,
                    }));
                    console.log(
                      "[*]category",
                      getkeyName(category),
                      "[*]deviceId",
                      device.deviceId
                    );
                  }
                });
              });
            });
          }
        
        }
      }
      setUncategorizedDevices(uncategorizedDeviceslist);
   
    } catch (e) {
      console.log("[not authorized camera error]", e);
      // acceptCameraToast("Veuillez autoriser l'accès à la caméra");
    }
  };

  let currentlabels: any[] = [];

  const acceptCameraToast = (text: string) => {
    hotToast(
      (t) => (
        <span className="flex">
          {text}
          <button
            onClick={() => {
              hotToast.dismiss(t.id);
            }}
          >
            Ok
          </button>
        </span>
      ),
      {
        icon: <WebRTCCamera />,
      }
    );
  };

  useEffect(() => {
    handelDeviceChange();
    if (navigator.mediaDevices && navigator.mediaDevices.addEventListener) {
      navigator.mediaDevices.addEventListener(
        "devicechange",
        handelDeviceChange
      );
    } else {
      console.error("MediaDevices API is not supported in this browser.");
      toast.warning("veillez utiliser un navigateur compatible comme : Chrome");
    }

    return () => {
      agentSocket.off();
      agentSocket.disconnect();
      if (deviceRef.current?.srcObject) {
        handleRemoveStream();
      }
    };
  }, []);

  // hanlers
  const handelDeviceClick = (deviceIndex: number, deviceName: string) => {
    console.log("toolsindex index", deviceIndex);
    console.log("toolsindex toolsindex", toolsIndex);
    // check the previous value
    if (toolsIndex === 5 && !!toolsIndex != deviceIsClicked) {
      toast.success("Echographie est bien arrêté");
      agentSocket.emit("usg_cmd_off");
      agentSocket.off("usg_cmd_off");
      agentSocket?.off("usg_cmd");
      agentSocket?.off("usg");
    }
    setToolName(deviceName);
    if (!toolsIndex) setToolsIndex(deviceIndex);
    if (toolsIndex && toolsIndex != deviceIndex) {
      handleRemoveStream();
      setToolsIndex(deviceIndex);
    }
    if (toolsIndex == deviceIndex) {
      handleRemoveStream();
      setToolsIndex(null);
    }
  };

  return (
    <GlobalStyle>
      {" "}
      <div className="flex flex-col  items-center justify-start px-4 py-2 overflow-hidden ">
        <div className="title flex gap-2 items-center  w-full mt-6 mb-10">
          <div className="flex items-center">
            <button onClick={handleBackButtonClick}>
              <ArrowLeftCircleIcon className="w-11" />
            </button>
          </div>
          <div className="text-primary text-xl font-bold ">
            Périphériques avancés
          </div>
        </div>
        <div className="flex gap-20 h-[70%] w-full">
          <div className="tools w-[20%] flex flex-col justify-between gap-3">
            {AdvancedParamsTools.map((tool, index) => (
              <div
                onClick={() => {
                  if (
                    (availableVideaoDevices &&
                      availableVideaoDevices[tool.name]) ||
                    /*availableSensors[tool.name] &&*/ tool.name === "ecg" ||
                    tool.name === ExeName.ECHO ||
                    availableServices.includes(tool.name)
                  ) {
                    handelDeviceClick(index, tool.name);
                  }
                }}
                key={index}
                className={`${
                  toolsIndex == index
                    ? // && availableVideaoDevices[tool.name]
                      "border-primary"
                    : "border-[#D7D7D7] "
                }  flex gap-10 p-3 p- items-center border-2 justify-between cursor-pointer rounded-xl
                ${
                  (availableVideaoDevices &&
                    availableVideaoDevices[tool.name]) ||
                  /*availableSensors[tool.name] &&*/ tool.name === "ecg" ||
                  tool.name === ExeName.ECHO ||
                  availableServices.includes(tool.name)
                    ? ""
                    : "opacity-50"
                }`}
              >
                <img src={tool.icon} />
                <div className={` text-[#5D5D5D] w-[70%] text-lg font-bold`}>
                  {tool.displayedName}
                </div>
              </div>
            ))}
          </div>
          <div className="stream w-[80%] border-[#D7D7D7] border-2 rounded-xl h-auto flex">
            {toolsIndex === 5 ? (
              <FileDropZone importedFiles={importedFiles} />
            ) : (
              <DeviceVideo ref={deviceRef} />
            )}

            {deviceRef && (
              <NoStream
                deviceRef={deviceRef}
                toolsIndex={toolsIndex}
                toolName={toolName}
              />
            )}
          </div>
        </div>
      </div>
    </GlobalStyle>
  );
};

export default AdvancedParametere;