import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import toast from 'react-hot-toast';
import 'react-toastify/dist/ReactToastify.css';
import DeleteIcon from "./../../assets/DeleteIcon.svg";
import imageIcon from "./../../assets/imageIcon.svg";
import { keyframes } from 'styled-components';
import { Dialog, DialogBody, DialogFooter, DialogHeader, Spinner, Textarea } from '@material-tailwind/react';
import { PiLinkSimpleHorizontalBreakLight } from 'react-icons/pi';
import { usePostAttachementMutation } from './../../features/Examen/advancedParamsApi';
import { CapturedDeviceType } from '../../enums/advancedParams';
import APP_CONSTANTS from '../../constants';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';

export const FileDropZone = (props: any) => {
    const [files, setFiles] = useState([]);
    const [open, setOpen] = useState(false);
    const [imageIndex, setImageIndex] = useState(-1);
    const params = useParams();
    const [postAttachement, { isLoading, isSuccess, isError }] =
        usePostAttachementMutation();
    const storedUser = useSelector((state: RootState) => state?.auth?.user);
    const [inputValue, setInputValue] = useState('');
    const handleInputChange = (event: any) => {
        setInputValue(event.target.value);
    };

    const {
        acceptedFiles,
        fileRejections,
        getRootProps,
        getInputProps
    } = useDropzone({
        accept: {
            'image/*': []
        },
        onDrop: acceptedFiles => {
            // check if the file name and size already exist in the array
            setFiles((prevFiles: any): any => {
                const newFiles = acceptedFiles.filter(file => {
                    const duplicateFile = prevFiles.find((prevFile: File) => {
                        return prevFile.name === file.name && prevFile.size === file.size;
                    });
                    return !duplicateFile;
                }).map(file => Object.assign(file, {
                    preview: URL.createObjectURL(file)
                }));
                if (newFiles.length === 0) {
                    toast.error('le fichier existe déjà');
                }
                return [...prevFiles, ...newFiles]
                    ;
            });
        },
        maxFiles: 15
    });

    const thumbs = files?.map((file, index) => (
        <div style={thumb} key={file.name}>
            <div style={thumbInner}>
                <img
                    src={file.preview}
                    style={img}
                    // Revoke data uri after image is loaded
                    // onLoad={() => { URL.revokeObjectURL(file.preview) }}
                    onClick={() => { handleOpen(index) }}
                />
            </div>
            <div>
                <img src={DeleteIcon} className={"cursor-pointer h-[55px] w-[55px]"} style={shakingAnimation}
                    onClick={() => {
                        handelDeleteImage(index)
                    }}></img>
            </div>
        </div>
    ));

    const handelDeleteImage = (index: number) => {
        //delete the file from the array with the index
        const newFiles = files.filter((file: any, i: number) => i !== index);
        setFiles(newFiles);
        toast.success('Image deleted');
    }
    const handelSendData = () => {
        console.log("post Attachement", files[imageIndex]);
        console.log("post Attachement", storedUser);  
        const data = new FormData();
        // data.append("file", chosenImage as Blob);
        data.append("name", files[imageIndex]?.name);
        data.append("type", CapturedDeviceType.ECHOGRAPHE);
        data.append("userId", localStorage.getItem(`${APP_CONSTANTS.LOCALSTORAGE_PREFIX}userId`)!);
        data.append("consultationId", params.consultationId!);
        data.append("specialityId", storedUser?.specialityId ?? storedUser?.doctor.speciality.id);
        data.append("file", files[imageIndex] as Blob);
        data.append("isScreenShot", "false");
        data.append("comment", `doctor comment: ${inputValue}.`);
        postAttachement(data);
        setOpen(false);
        isSuccess && toast.success("Image envoyée avec succès");
        isError && toast.error("Erreur lors de l'envoi de l'image");
    };
    const handleOpen = (index: number) => {
        setImageIndex(index);
        setOpen(!open);
    }
    useEffect(() => {

        // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
        return () => files.forEach(file => URL.revokeObjectURL(file.preview));
    }, []);

    return (

        <>
            <section className="container">

                <label htmlFor="dropzone-file" className="flex items-center justify-center w-full h-full border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">

                    <div  {...getRootProps({ className: 'h-full w-full' })}>
                        <input {...getInputProps()} />

                        <div className="flex flex-col items-center justify-center pt-[35%] pb-6">
                            <svg className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                            </svg>
                            <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                            <p className="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
                        </div>
                    </div>
                </label>
                <aside style={thumbsContainer}>
                    {thumbs}
                </aside>
            </section>
            <Dialog open={open} handler={handleOpen}>
                <DialogBody>
                    <DialogHeader>
                        <img src={imageIcon} className='w-[30px] h-[30px] mr-4'></img>{files[imageIndex]?.path}
                    </DialogHeader>
                    <img
                        className="h-full w-full object-cover object-center"
                        onLoad={(event) => {
                            // Revoke the data uri after the image is loaded
                            //    { URL.revokeObjectURL(files[imageIndex].preview) }
                        }}
                        src={files[imageIndex]?.preview}
                    />
                    <div className="items-center gap-3 m-5">
                        <span className='font-bold text-lg'>veuillez Inserer votre commentaire:</span>
                        {/* avis Specialist */}
                        <Textarea size="lg"
                            value={inputValue}
                            onChange={handleInputChange}
                            variant="outlined"
                            label="Votre avis ...." />
                    </div>
                    <div className="col-span-4 flex justify-between mt-5 m-5">
                        <button
                            type="button"
                            className=" bg-[#858585] text-white w-36 px-5 py-2 rounded font-bold" onClick={() => setOpen(false)}
                        >
                            Annuler
                        </button>
                        <button
                            type="submit"
                            className="bg-[#1AA5C0] text-white w-36 px-5 py-2 rounded font-bold"
                            onClick={() => handelSendData()}
                        >
                            {!isLoading ? "Sauvegarder" : <Spinner />}
                        </button>
                    </div>
                </DialogBody>
            </Dialog>
        </>
    );
}

const thumbsContainer = {
    // display: 'flex',
    // flexDirection: 'row',
    // flexWrap: 'wrap',
    // marginTop: 16,
    marginTop: '-55rem',
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 10,
    border: '5px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    padding: 4,
    boxSizing: 'border-box',
    '@keyframes shake': {
        ' 0%': { transform: 'translate(1px, 1px) rotate(0deg)' },
        '10%': { transform: 'translate(-1px, -2px) rotate(-1deg)' },
        '20%': { transform: 'translate(-3px, 0px) rotate(1deg)' },
        ' 30% ': { transform: 'translate(3px, 2px) rotate(0deg)' },
        '40%': { transform: 'translate(1px, -1px) rotate(1deg)' },
        '50%': { transform: 'translate(-1px, 2px) rotate(-1deg)' },
        '60%': { transform: 'translate(-3px, 1px) rotate(0deg)' },
        '70%': { transform: 'translate(3px, 1px) rotate(-1deg)' },
        '80%': { transform: 'translate(-1px, -1px) rotate(1deg)' },
        ' 90%': { transform: 'translate(1px, 2px) rotate(0deg)' },
        '100%': { transform: 'translate(1px, -2px) rotate(-1deg)' }
    }

};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden',
    width: 350,
    height: 200,

};
const shakingAnimation = {
    animation: 'shake 0.5s',
    // animationIterationCount: 'infinite'
    ...thumb['@keyframes shake'],
}


const img = {
    display: 'block',
    width: 'auto',
    height: '100%',
    cursor: 'pointer',
};
